import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import TestRunnerClient from "../../../api/clients/TestRunnerClient";
import { toast } from "react-toastify";
import { options } from "../toastify";

const EhrIntegrationPerformanceModal = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
    setPayload(defaultPayload);
  };

  const defaultHapiUrl =
    "https://fhir.klara-performance-us.com/hapi-fhir-jpaserver/fhir";
  const defaultPatientCount = 100;
  const defaultAppointmentCount = 100;
  const defaultAppointmentDelta = 1;
  const defaultAppointmentStatus = "pending";
  const defaultBranch = "main";
  const defaultOptionSelected = "create";

  const defaultPayload = {
    hapiUrl: defaultHapiUrl,
    branch: defaultBranch,
    patientCount: defaultPatientCount,
    appointmentCount: defaultAppointmentCount,
    appointmentDelta: defaultAppointmentDelta,
    appointmentStatus: defaultAppointmentStatus,
    runPatientUpdate: false,
    runPatientCreate: true,
    runAppointmentsCreate: false,
  };

  const [payload, setPayload] = useState(defaultPayload);
  const [optionSelected, setOptionSelected] = useState(defaultOptionSelected);

  const isFormInvalid = () => {
    return (
      !payload.hapiUrl ||
      (payload.runPatientCreate && !payload.patientCount) ||
      (payload.runPatientUpdate && !payload.patientCount) ||
      (payload.runAppointmentsCreate && !payload.appointmentCount)
    );
  };

  const handleSubmit = async (event) => {
    try {
      await TestRunnerClient.runEhrPerformance(payload);
      toast.success("ehr-performance pipeline started", options);
      setShow(false);
      setPayload(defaultPayload);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Data preparation for performance tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput0">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  if (e.target.value === "create") {
                    setOptionSelected("create");
                    setPayload({
                      ...payload,
                      runPatientUpdate: false,
                      runPatientCreate: true,
                      runAppointmentsCreate: false,
                    });
                  } else if (e.target.value === "update") {
                    setOptionSelected("update");
                    setPayload({
                      ...payload,
                      runPatientUpdate: true,
                      runPatientCreate: false,
                      runAppointmentsCreate: false,
                    });
                  } else if (e.target.value === "appointments") {
                    setOptionSelected("appointments");
                    setPayload({
                      ...payload,
                      runPatientUpdate: false,
                      runPatientCreate: false,
                      runAppointmentsCreate: true,
                    });
                  }
                }}
              >
                <option value="create">Create Patients</option>
                <option value="update">Update Patients</option>
                <option value="appointments">Create Appointments</option>
              </Form.Control>
            </Form.Group>

            {optionSelected === "create" && (
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Number of patients to create</Form.Label>
                <Form.Control
                  required
                  isInvalid={!payload.patientCount}
                  placeholder={"100"}
                  defaultValue={defaultPatientCount}
                  autoFocus
                  autoComplete="off"
                  onChange={(e) =>
                    setPayload({ ...payload, patientCount: +e.target.value })
                  }
                />
              </Form.Group>
            )}

            {optionSelected === "update" && (
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Number of patients to update</Form.Label>
                <Form.Control
                  required
                  isInvalid={!payload.patientCount}
                  placeholder={"100"}
                  defaultValue={defaultPatientCount}
                  autoFocus
                  autoComplete="off"
                  onChange={(e) =>
                    setPayload({ ...payload, patientCount: +e.target.value })
                  }
                />
              </Form.Group>
            )}

            {optionSelected === "appointments" && (
              <>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Number of appointments to create</Form.Label>
                  <Form.Control
                    required
                    isInvalid={!payload.appointmentCount}
                    placeholder={"100"}
                    defaultValue={defaultAppointmentCount}
                    autoFocus
                    autoComplete="off"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        appointmentCount: +e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Delta</Form.Label>
                  <Form.Control
                    required
                    isInvalid={!payload.appointmentDelta}
                    placeholder={"1"}
                    defaultValue={defaultAppointmentDelta}
                    autoFocus
                    autoComplete="off"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        appointmentDelta: +e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    required
                    isInvalid={!payload.appointmentStatus}
                    placeholder={"pending"}
                    defaultValue={defaultAppointmentStatus}
                    autoFocus
                    autoComplete="off"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        appointmentStatus: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-2" controlId="exampleForm.ControlInput5">
              <Form.Label>Hapi url</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.hapiUrl}
                placeholder={defaultHapiUrl}
                defaultValue={defaultHapiUrl}
                autoFocus
                autoComplete="off"
                onChange={(e) =>
                  setPayload({ ...payload, hapiUrl: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFormInvalid()}
            variant="primary"
            onClick={handleSubmit}
          >
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EhrIntegrationPerformanceModal;
