import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { options } from '../toastify';
import TestRunnerClient from '../../../api/clients/TestRunnerClient';

const IntegrationModal = ({ show, setShow }) => {

  const handleClose = () => {
    setShow(false);
    setPayload(defaultPayload)
  };

  const defaultTag = 'latest'
  const placeHolder = 'CORE-1001'
  const defaultBranch = 'rc'

  const defaultPayload = { branch: defaultBranch, ehrTag: defaultTag, coreTag: defaultTag, messagingBackendTag: defaultTag }
  const [payload, setPayload] = useState(defaultPayload)

  const isFormValid = () => {
    return !payload.branch || !payload.ehrTag || !payload.coreTag || !payload.messagingBackendTag
  }

  const handleSubmit = async (event) => {
    try {
      await TestRunnerClient.runIntegration(payload);
      toast.success('e2e tests started', options)
      setShow(false)
      setPayload(defaultPayload)
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Start e2e tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>branch</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.branch}
                type="text"
                placeholder={placeHolder}
                defaultValue={defaultBranch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, branch: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>ehr-integration</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.ehrTag}
                placeholder={placeHolder}
                defaultValue={payload.ehrTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, ehrTag: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
              <Form.Label>core</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.coreTag}
                placeholder={placeHolder}
                defaultValue={payload.coreTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, coreTag: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>messaging-backend</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.messagingBackendTag}
                placeholder={placeHolder}
                defaultValue={defaultTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, messagingBackendTag: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isFormValid()} variant="primary" onClick={handleSubmit}>
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default IntegrationModal;