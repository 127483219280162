import axios from "./axios";

export default class TestRunClient{

  static async skippedTests(){
    const response = await axios.get('/api/test-runs/skipped')

    return response;
  }

  static async flakyTests(){
    const response = await axios.get('/api/test-runs/flaky')

    return response;
  }
}
