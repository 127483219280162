import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = (props) => {
  return (
    <div className='loader'>
      <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
    </div>

  );
};

export default Loader;