
import axios from 'axios';
import APP_CONFIG from '../../config/config';

const instance = axios.create({
  baseURL: APP_CONFIG.BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('auth_token');
  let parsedToken;
  try{
    parsedToken = JSON.parse(token);
  } catch(error){}
  if (token) {
    config.headers.Authorization = `Bearer ${parsedToken?.value}`
  }

  return config;
})



export default instance;