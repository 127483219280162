import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import TestRunnerClient from '../../../api/clients/TestRunnerClient';
import { toast } from 'react-toastify';
import { options } from '../toastify';

const WebClientsFullModal = ({ show, setShow }) => {

  const handleClose = () => {
    setShow(false);
    setPayload(defaultPayload)
  };

  const placeHolder = 'CORE-1001'
  const defaultBranch = 'rc'
  const defaultTag = 'latest'

  const defaultPayload = { branch: defaultBranch, coreBranch: defaultBranch, ehrIntegrationTag: defaultTag, messagingBackendTag: defaultTag, paymentsBackendTag: defaultTag }

  const [payload, setPayload] = useState(defaultPayload)

  const isFormValid = () => {
    return !payload.branch || !payload.coreBranch || !payload.ehrIntegrationTag || !payload.messagingBackendTag || !payload.paymentsBackendTag
  }

  const handleSubmit = async (event) => {
    try {
      await TestRunnerClient.runWebClients(payload);
      toast.success('web-clients pipeline has been started', options)
      setShow(false)
      setPayload(defaultPayload)
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Run web-clients pipeline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>branch</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.branch}
                placeholder={placeHolder}
                defaultValue={defaultBranch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, branch: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>core</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.coreBranch}
                placeholder={placeHolder}
                defaultValue={defaultBranch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, coreBranch: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>ehr-integration</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.ehrIntegrationTag}
                placeholder={placeHolder}
                defaultValue={defaultTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, ehrIntegrationTag: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>messaging-backend</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.messagingBackendTag}
                placeholder={placeHolder}
                defaultValue={defaultTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, messagingBackendTag: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>payments-backend</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.paymentsBackendTag}
                placeholder={placeHolder}
                defaultValue={defaultTag}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, paymentsBackendTag: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isFormValid()} variant="primary" onClick={handleSubmit}>
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WebClientsFullModal;
