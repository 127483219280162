import React, { useEffect, useState } from 'react';
import UserClient from '../api/clients/UserClient';
import { useRequest } from '../hooks/useRequest';
import Table from 'react-bootstrap/Table';
import { Badge } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Users = (props) => {

  const [users, setUsers] = useState([])

  const [fetchUsers, isUsersLoading, error] = useRequest(async () => {
    const response = await UserClient.getAll()
    setUsers(response.data)
  })

  useEffect(() => {
    fetchUsers()
  }, [])


  return (
    <Container fluid="md" className="justify-content-md-center" style={{ 'paddingTop': 25 }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>email</th>
            <th>active</th>
            <th>role</th>
          </tr>
        </thead>
        <tbody>
          {
            users.map(user =>
            (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>
                  {user.active
                    ?
                    <Badge bg="primary">Active</Badge>
                    :
                    <Badge bg="secondary">Disabled</Badge>
                  }
                </td>
                <td>{user.role}</td>
              </tr>
            )
            )
          }
        </tbody>
      </Table >
    </Container>
  );

};

export default Users;