import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { useRequest } from '../hooks/useRequest';
import TestRunClient from '../api/clients/TestRunClient';
import { HashLink as Link } from 'react-router-hash-link';

const Dashboard = (props) => {

  const [skippedTests, setSkippedTests] = useState()
  const [flakyTests, setFlakyTests] = useState()


  const [fetchSkippedTests, isUsersLoading, error] = useRequest(async () => {
    const response = await TestRunClient.skippedTests()
    setSkippedTests(response.data)
  })

  const [fetchFlakyTests, isFlakyTestsLoading, flakyTestError] = useRequest(async () => {
    const response = await TestRunClient.flakyTests()
    setFlakyTests(response.data)
  })


  useEffect(() => {
    fetchSkippedTests()
    fetchFlakyTests()
  }, [])

  return (
    <Container fluid="md" className="justify-content-md-center" style={{}}>

      <br />
      <div id="flaky" style={{ fontWeight: "bold" }}>Flaky tests. Last sync: {flakyTests?.name.replace('test-results/rc/', '').slice(0, -1)}
        <Link to='#flaky'></Link>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Spec</th>
            <th>Test name</th>
            <th>10 builds</th>
            <th>3 builds</th>
            <th>last build</th>
          </tr>
        </thead>
        <tbody>
          {
            flakyTests?.tests.map((test, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{test.spec}</td>
                <td>{test.testName}</td>
                <td>{test.unsuccessRate}</td>
                <td>{test.unsuccessRate3Builds}</td>
                <td>{test.lastRunSucess ? 'success' : 'failed'}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <div id="skipped" style={{ fontWeight: "bold" }}>Skipped tests. Last sync: {skippedTests?.name.replace('test-results/rc/', '').slice(0, -1)}
        <Link to='#skipped'></Link>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Spec</th>
            <th>Test name</th>
          </tr>
        </thead>
        <tbody>
          {
            skippedTests?.tests.map((test, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{test.spec}</td>
                <td>{test.testName}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Container >
  );
};

export default Dashboard;