import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import TestRunnerClient from '../../../api/clients/TestRunnerClient';
import { toast } from 'react-toastify';
import { options } from '../toastify';

const WebCLientsModal = ({ show, setShow }) => {

  const handleClose = () => {
    setShow(false);
    setPayload(defaultPayload)
  };

  const placeHolder = 'CORE-1001'
  const defaultBranch = 'rc'
  const specsPlaceHolder = 'cypress/specs-robotic/groupConversations/groupConversation.js'
  const defaultMachines = 1
  const defaultRunTests = true


  const defaultPayload = { branch: defaultBranch, run_functional_tests_only: defaultRunTests, machines: defaultMachines, spec_path: '' }
  const [payload, setPayload] = useState(defaultPayload)

  const isFormValid = () => {
    return !payload.branch || !payload.machines || !payload.spec_path
  }

  const handleSubmit = async (event) => {
    try {
      await TestRunnerClient.runWebClients(payload);
      toast.success('cypress spec started', options)
      setShow(false)
      setPayload(defaultPayload)
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Run cypress spec on demand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>branch</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.branch}
                placeholder={placeHolder}
                defaultValue={defaultBranch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, branch: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>machines</Form.Label>
              <Form.Control
                required
                isInvalid={!payload.machines}
                placeholder={defaultMachines}
                defaultValue={defaultMachines}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, machines: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>run_functional_tests_only</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                defaultChecked={payload.run_functional_tests_only}
                onChange={(e) => setPayload({ ...payload, run_functional_tests_only: !!e.toggleEnabled })}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
              <Form.Label>spec_paths</Form.Label>
              <Form.Control
                placeholder={specsPlaceHolder}
                defaultValue={''}
                autoFocus
                autoComplete="off"
                onChange={(e) => setPayload({ ...payload, spec_path: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isFormValid()} variant="primary" onClick={handleSubmit}>
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WebCLientsModal;