import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, Outlet } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AuthContext from "../context";
import IntegrationModal from "./ui/modals/IntegrationModal";
import WebCLientsModal from "./ui/modals/WebClientsModal";
import WebClientsFullModal from "./ui/modals/WebClientsFullModal";
import EhrIntegrationPerformanceModal from "./ui/modals/EhrIntegrationPerformanceModal";
import HourlySyncModal from "./ui/modals/HourlySyncModal";
import ClearTodaysJourneyExecution from "./ui/modals/ClearTodaysJourneyExecutionModal";
import JourneyExecution from "./ui/modals/JourneyExecutionModal";
import { FormGroup } from "react-bootstrap";

const AppNavbar = props => {
  const { setIsAuth, ehrJwtToken, setEhrJwtToken } = useContext(AuthContext);

  const [integrationPopup, setIntegrationPopup] = useState(false);
  const [webClientsPopup, setWebClientsPopup] = useState(false);
  const [webClientsFullModal, setWebClientsFullModal] = useState(false);
  const [ehrIntegrationPerformanceModel, setEhrIntegrationPerformanceModel] = useState(false);
  const [hourlySyncModal, setHourlySyncModal] = useState(false);
  const [clearTodaysJourneyExecution, setClearTodaysJourneyExecution] = useState(false);
  const [journeyExecution, setJourneyExecution] = useState(false);

  const logout = () => {
    setIsAuth(false);
    localStorage.clear();
  };

  const handleEhrToken = token => {
    localStorage.setItem(
      "ehr_jwt_token",
      JSON.stringify({
        value: token,
        expiry: new Date().getTime() + 1000 * 60 * 60 * 1, // 1 hr
      })
    );
    setEhrJwtToken(token);
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            QA-Portal
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/dashboard">
                Dashboard
              </Nav.Link>
              <Nav.Link as={Link} to="/users">
                Users
              </Nav.Link>
              <NavDropdown title="Tests" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => setIntegrationPopup(true)}>
                  E2E tests
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setWebClientsPopup(true)}>
                  Web-Clients(Cypress - on demand)
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setWebClientsFullModal(true)}>
                  Web-Clients(Pipeline)
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Ehr-integration" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => setEhrIntegrationPerformanceModel(true)}>
                  Performance(data preparation)
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setHourlySyncModal(true)}>
                  Hourly sync
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setClearTodaysJourneyExecution(true)}>
                  Clear todays journeys
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setJourneyExecution(true)}>
                  Journey execution
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Ehr-integration JWT token" id="basic-nav-dropdown">
                <Form>
                  <FormGroup className="m-2" controlId="controlInput_ehr">
                    <Form.Text>
                      Go to{" "}
                      {<strong>https://ehr-integration.klara-staging3.com/admin/token</strong>} and
                      copy the value.
                    </Form.Text>
                    <Form.Control
                      autoFocus
                      autoComplete="off"
                      type="password"
                      defaultValue={ehrJwtToken}
                      onChange={e => handleEhrToken(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Button variant="primary" onClick={logout}>
            Logout
          </Button>
        </Container>
      </Navbar>
      <Outlet />
      <IntegrationModal show={integrationPopup} setShow={setIntegrationPopup} />
      <WebCLientsModal show={webClientsPopup} setShow={setWebClientsPopup} />
      <WebClientsFullModal show={webClientsFullModal} setShow={setWebClientsFullModal} />
      <EhrIntegrationPerformanceModal
        show={ehrIntegrationPerformanceModel}
        setShow={setEhrIntegrationPerformanceModel}
      />
      {/* Need EHR JWT token */}
      <HourlySyncModal show={hourlySyncModal} setShow={setHourlySyncModal} />
      <ClearTodaysJourneyExecution
        show={clearTodaysJourneyExecution}
        setShow={setClearTodaysJourneyExecution}
      />
      <JourneyExecution show={journeyExecution} setShow={setJourneyExecution} />
    </>
  );
};

export default AppNavbar;
