import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../../context";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import TestRunnerClient from "../../../api/clients/TestRunnerClient";
import { toast } from "react-toastify";
import { options } from "../toastify";

const JourneyExecution = ({ show, setShow }) => {
  const { ehrJwtToken } = useContext(AuthContext);

  useEffect(() => {
    setEveningWorkerPayload({ ...eveningWorkerPayload, ehrJwtToken });
  }, [ehrJwtToken]);

  const handleClose = () => {
    setShow(false);
    setIsAcrossAsync(false);
    setEveningWorkerPayload(defaultEveningWorkerPayload);
  };

  const defaultEveningWorkerPayload = {
    teamId: null,
    environment: "epic",
    timezone: "US/Pacific",
    journeySessionName: "prior",
    ehrJwtToken,
  };

  const [eveningWorkerPayload, setEveningWorkerPayload] = useState(defaultEveningWorkerPayload);
  const [isAcrossAsync, setIsAcrossAsync] = useState(false);

  const isEveningWorkerFormValid = () => {
    return (
      !eveningWorkerPayload.teamId ||
      !eveningWorkerPayload.environment ||
      (!eveningWorkerPayload.timezone && isAcrossAsync) ||
      !ehrJwtToken
    );
  };

  const handleEveningWorkerSubmit = async event => {
    try {
      if (isAcrossAsync) {
        await TestRunnerClient.runJourneysAcrossTimezones(eveningWorkerPayload);
      } else {
        await TestRunnerClient.runEveningWorker({
          teamId: eveningWorkerPayload.teamId,
          environment: eveningWorkerPayload.environment,
          ehrJwtToken,
        });
      }

      toast.success("Journey execution has been started", options);
      setShow(false);
      setEveningWorkerPayload(defaultEveningWorkerPayload);
    } catch (e) {
      e.request.status === 401
        ? toast.error("Unauthorized. Please set a valid Ehr JWT token.")
        : toast.error(e.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Journey execution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput0">
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Across timezones</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  defaultChecked={isAcrossAsync}
                  onChange={e => setIsAcrossAsync(e.target.checked)}
                />
              </Form.Group>
              <Form.Label>Environment</Form.Label>
              <Form.Control
                as="select"
                onChange={e => {
                  setEveningWorkerPayload({ ...eveningWorkerPayload, environment: e.target.value });
                }}
              >
                <option value="epic">epic</option>
                <option value="performance-us">performance-us</option>
                <option value="rc">rc</option>
                <option value="staging">staging</option>
                <option value="staging2">staging2</option>
                <option value="staging3">staging3</option>
                <option value="team5-staging">team5-staging</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
              <Form.Label>Team id</Form.Label>
              <Form.Control
                required
                isInvalid={!eveningWorkerPayload.teamId}
                placeholder={"1000"}
                autoFocus
                autoComplete="off"
                onChange={e =>
                  setEveningWorkerPayload({ ...eveningWorkerPayload, teamId: +e.target.value })
                }
              />
            </Form.Group>
            {isAcrossAsync && (
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">
                <Form.Label>Timezone</Form.Label>
                <Form.Control
                  required
                  isInvalid={!eveningWorkerPayload.timezone}
                  defaultValue={eveningWorkerPayload.timezone}
                  placeholder={eveningWorkerPayload.timezone}
                  autoFocus
                  autoComplete="off"
                  onChange={e =>
                    setEveningWorkerPayload({ ...eveningWorkerPayload, timezone: e.target.value })
                  }
                />
              </Form.Group>
            )}
            {isAcrossAsync && (
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput5">
                <Form.Label>Journey session name</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => {
                    setEveningWorkerPayload({
                      ...eveningWorkerPayload,
                      journeySessionName: e.target.value,
                    });
                  }}
                >
                  <option value="prior">prior</option>
                  <option value="post">post</option>
                  <option value="same_day">same_day</option>
                  <option value="staging3">staging3</option>
                  <option value="scheduled">scheduled</option>
                  <option value="text_to_pay">text_to_pay</option>
                  <option value="recall">recall</option>
                </Form.Control>
              </Form.Group>
            )}
            {!ehrJwtToken && (
              <Form.Floating className="mt-2 text-danger">
                Ehr-integration JWT token is not set.
              </Form.Floating>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isEveningWorkerFormValid()}
            variant="primary"
            onClick={handleEveningWorkerSubmit}
          >
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JourneyExecution;
