import { removeBlankAttributes } from "../utils/utils";
import axios from "./axios";

export default class TestRunnerClient {
  static async runIntegration(body) {
    const response = await axios.post("/api/circleci/workflow/integration", JSON.stringify(body));

    return response;
  }

  static async runWebClients(body) {
    const response = await axios.post("/api/circleci/workflow/web-clients", JSON.stringify(body));

    return response;
  }

  static async runEhrPerformance(body) {
    const response = await axios.post("/api/faktory/ehr-performance", JSON.stringify(body));

    return response;
  }

  static async runHourlySync(body) {
    const parsedBody = removeBlankAttributes(body);
    const response = await axios.post(
      "/api/ehr-integration/hourly_sync",
      JSON.stringify(parsedBody)
    );

    return response;
  }

  static async runEveningWorker(body) {
    const response = await axios.post("/api/ehr-integration/evening_worker", JSON.stringify(body));

    return response;
  }

  static async runJourneysAcrossTimezones(body) {
    const response = await axios.post(
      "/api/ehr-integration/run_journey_session",
      JSON.stringify(body)
    );

    return response;
  }

  static async clearTodaysJourneyExecutions(body) {
    const response = await axios.post(
      "/api/ehr-integration/clear_todays_journey_executions",
      JSON.stringify(body)
    );

    return response;
  }
}
