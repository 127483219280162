import { createBrowserRouter,  Navigate } from "react-router-dom"
import AppNavbar from "../components/AppNavbar"
import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login"
import Users from "../pages/Users"



export const privateRoutes = createBrowserRouter([
  {
    element: <AppNavbar />,
    children: [
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '*',
        element: < Navigate to="/users" replace />
      }
    ]
  }
])

export const publicRoutes = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '*',
    element: < Navigate to="/login" replace />
  }
])
