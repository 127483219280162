import './styles/App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import AppRouter from './components/AppRouter';
import { useContext, useEffect } from 'react';
import AuthContext from './context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const { isAuth, setIsAuth, setIsLoading } = useContext(AuthContext)


  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    let parsedToken;
    try{
      parsedToken = JSON.parse(token)
    } catch(e){}

    if (parsedToken && parsedToken?.expiry > new Date().getTime()) {
      setIsAuth(true)
    }
    setIsLoading(false)
  }, [isAuth])

  return (
    <div className="App">
      <AppRouter />
      <ToastContainer />
    </div>
  );
}

export default App;
