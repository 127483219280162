import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../../context";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import TestRunnerClient from "../../../api/clients/TestRunnerClient";
import { toast } from "react-toastify";
import { options } from "../toastify";

const HourlySyncModal = ({ show, setShow }) => {
  const { ehrJwtToken } = useContext(AuthContext);

  useEffect(() => {
    setPayload({ ...payload, ehrJwtToken });
  }, [ehrJwtToken]);

  const handleClose = () => {
    setShow(false);
    setIsEnterprise(false);
    setPayload(defaultPayload);
  };

  const defaultPayload = { teamId: null, populationId: null, environment: "epic", ehrJwtToken };

  const [payload, setPayload] = useState(defaultPayload);
  const [isEnterprise, setIsEnterprise] = useState(false);

  const isFormValid = () => {
    return (
      !payload.environment ||
      (isEnterprise && !payload.populationId) ||
      (!isEnterprise && !payload.teamId) ||
      !ehrJwtToken
    );
  };

  const handleSubmit = async event => {
    try {
      await TestRunnerClient.runHourlySync(payload);
      toast.success("hourly sync started", options);
      setShow(false);
      setPayload(defaultPayload);
    } catch (e) {
      e.request.status === 401
        ? toast.error("Unauthorized. Please set a valid Ehr JWT token.")
        : toast.error(e.message);
    }
  };

  const switchIsEnterprise = event => {
    setPayload({
      ...defaultPayload,
      environment: payload.environment,
      ehrJwtToken,
    });
    setIsEnterprise(event.target.checked);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Start hourly sync</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>Enterprise</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                defaultChecked={isEnterprise}
                onChange={e => switchIsEnterprise(e)}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput0">
              <Form.Label>Environment</Form.Label>
              <Form.Control
                as="select"
                onChange={e => {
                  setPayload({ ...payload, environment: e.target.value });
                }}
              >
                <option value="epic">epic</option>
                <option value="performance-us">performance-us</option>
                <option value="rc">rc</option>
                <option value="staging">staging</option>
                <option value="staging2">staging2</option>
                <option value="staging3">staging3</option>
                <option value="team5-staging">team5-staging</option>
              </Form.Control>
            </Form.Group>
            {!isEnterprise && (
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Team id</Form.Label>
                <Form.Control
                  required
                  isInvalid={!payload.teamId}
                  placeholder={"1000"}
                  autoFocus
                  autoComplete="off"
                  onChange={e => setPayload({ ...payload, teamId: +e.target.value })}
                />
              </Form.Group>
            )}
            {isEnterprise && (
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
                <Form.Label>Population id</Form.Label>
                <Form.Control
                  required
                  isInvalid={!payload.populationId}
                  placeholder={""}
                  autoFocus
                  autoComplete="off"
                  onChange={e => setPayload({ ...payload, populationId: e.target.value })}
                />
              </Form.Group>
            )}
            {!ehrJwtToken && (
              <Form.Floating className="mt-2 text-danger">
                Ehr-integration JWT token is not set.
              </Form.Floating>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isFormValid()} variant="primary" onClick={handleSubmit}>
            Start
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HourlySyncModal;
