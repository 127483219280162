import React, { useContext, useState } from 'react';
import LoginClient from '../api/clients/LoginClient'
import AuthContext from '../context';
import { toast } from 'react-toastify';

const Login = (props) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { setIsAuth } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await LoginClient.login({ email, password })
      localStorage.setItem('auth_token', JSON.stringify({
        value: response.data.access_token,
        expiry: new Date().getTime() + 1000 * 60 * 60 * 6 // 6 hrs
      }))
      setIsAuth(true)
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1d"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div></div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;