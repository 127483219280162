import React, { useContext } from 'react';
import { RouterProvider, } from 'react-router-dom';
import AuthContext from '../context';
import { privateRoutes, publicRoutes } from '../router';
import Loader from './ui/loader/Loader';

const AppRouter = (props) => {
  const { isAuth, isLoading } = useContext(AuthContext)
  if (isLoading) {
    return <Loader />
  }
  return (
    isAuth
      ?
      <RouterProvider router={privateRoutes} />
      :
      <RouterProvider router={publicRoutes} />
  );
};

export default AppRouter;